import React, { useState } from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer'

import { ReactComponent as ArrowRight } from "../assets/img/arrow-right.svg"
import PrimaryButton from '../components/base/PrimaryButton';
import { Link } from 'react-router-dom';


const OfferStats = ({ totalOffers, totalAccepted }) => (
    <table>
        <thead>
            <tr >
                <th className="w-1/2 relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                    <div className="flex items-center text-center justify-center">
                        <span className="" >Total Offers</span>
                    </div>
                </th>
                <th className="w-1/2 relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                    <div className="flex items-center text-center justify-center">
                        <span className="" >Total Number of Offer Accepted</span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td
                    className={`text-center px-6 h-20 whitespace-nowrap`}
                >
                    {totalOffers}
                </td>
                <td
                    className={`text-center px-6 h-20 whitespace-nowrap`}
                >
                    {totalAccepted}
                </td>
            </tr>
        </tbody>
    </table>
);


const SourceRow = ({ rowData, isEven }) => (
    <tr>
        {rowData.map((children, idx) => (
            <td
                key={idx}
                className={`text-center px-6 h-20 whitespace-nowrap border-b border-solid border-zinc-300 ${!isEven ? "bg-neutral-100" : ""}`}
            >
                {children}
            </td>
        ))}
    </tr>
);

const DoubleValue = ({ value1, value2 }) => {
    return (
        (<div className="flex gap-3 justify-center px-20 py-7 max-md:px-5">
            <div className="font-bold">{value1}%</div>
            <div>{value2}</div>
        </div>)
    )
}


const SBM04_3 = () => {
    const [job, setJob] = useState()
    const [timePeriod, setTimePeriod] = useState()
    const hadleSelectJob = () => {
        setJob()
    }
    const handleSelectTimePeriod = () => {
        setTimePeriod()
    }

    const jobs = [
        { value: 'cleaning_technician', label: 'Cleaning Technician' },
        { value: 'job_2', label: 'job 2' },
        { value: 'job_3', label: 'job 3' },
    ];

    const totalOffers = 100;
    const totalAccepted = 50;

    const sources = [
        {
            dateAdStarted: '#12/23/2023',
            dateHired: '#12/23/2023',
            dateApplication: '#12/23/2023',
            daysToHire: 0,
            activities: 168,
            calls: 168,
            texts: 168,
            emails: 168
        },
        {
            dateAdStarted: '#12/23/2023',
            dateHired: '#12/23/2023',
            dateApplication: '#12/23/2023',
            daysToHire: 0,
            activities: 123,
            calls: 123,
            texts: 123,
            emails: 123
        },
        {
            dateAdStarted: '#12/23/2023',
            dateHired: '#12/23/2023',
            dateApplication: '#12/23/2023',
            daysToHire: 0,
            activities: 234,
            calls: 234,
            texts: 234,
            emails: 234
        },
        {
            dateAdStarted: '#12/23/2023',
            dateHired: '#12/23/2023',
            dateApplication: '#12/23/2023',
            daysToHire: 0,
            activities: 22,
            calls: 22,
            texts: 22,
            emails: 22
        },
        {
            dateAdStarted: '#12/23/2023',
            dateHired: '#12/23/2023',
            dateApplication: '#12/23/2023',
            daysToHire: 0,
            activities: 5,
            calls: 5,
            texts: 5,
            emails: 5
        },
    ];



    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Insights', 'Time to Hire Report']} />
            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800">
                <div className='flex flex-col gap-11 items-center w-full max-w-[1432px] px-4'>
                    <div className="flex flex-col w-full">
                        <div className='flex flex-col-reverse lg:flex-col w-full'>
                            <Link to={"/SBM04_1_1"} className='h-[60px] py-1 max-lg:text-[19px] w-full lg:max-w-[186px] mb-[26px] self-end flex justify-center items-center lg:text-[1.375rem] font-bold text-[19px]lg:h-[4.625rem] whitespace-nowrap bg-[#37B34A] rounded-md text-white hover:bg-[#002E67]'>Export CSV</Link>
                            <div className="w-full flex max-lg:flex-col lg:gap-5 justify-between text-lg">
                                <div className="flex flex-col flex-1 max-w-[550px]">
                                    <SelectField
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Job"
                                        name="job"
                                        id="job"
                                        value={job}
                                        onChange={hadleSelectJob}
                                        options={jobs}
                                    />
                                </div>
                                <div className="flex flex-col flex-1 max-w-[550px] whitespace-nowrap">
                                    <InputField
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Time Period"
                                        labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                        type="date" name="timePeriod" id="timePeriod"
                                        value={timePeriod}
                                        onChange={handleSelectTimePeriod}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-6 w-full text-lg rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:max-w-full overflow-auto">
                            <table className="max-w-full overflow-x-scroll whitespace-nowrap">
                                <thead>
                                    <tr className='w-full'>
                                        <th className="relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                                            <div className="text-center">Date Job Ad Started</div>
                                        </th>
                                        <th className="relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5">
                                            <div className="text-center">Date Hired</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Date of Application</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Time to Hire (days)</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Activities</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Calls</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Texts</div>
                                        </th>
                                        <th className='relative px-6 h-[90px] font-semibold border-b border-solid bg-neutral-100 border-zinc-300 text-stone-500 max-md:px-5'>
                                            <div className="text-center">Total Emails</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sources.map((source, idx) => (
                                        <SourceRow
                                            key={idx}
                                            rowData={[
                                                source.dateAdStarted,
                                                source.dateHired,
                                                source.dateApplication,
                                                source.daysToHire,
                                                source.activities,
                                                source.calls,
                                                source.texts,
                                                source.emails
                                            ]}
                                            isEven={idx % 2 === 0}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM04_3