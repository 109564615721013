import React from 'react'
import ModalTemplate from '../base/ModalTemplate';
export const SuccessModal = ({open, handleClose}) => {
  return (
    <ModalTemplate open={open} onClick={handleClose} modalSize={'modal__dialog--600'}>
        <div className="modal__body gap-[32px]">
            <h1 className='text-[56px] font-bold uppercase'>Success!</h1>
            <div className="shrink-0 h-1 bg-green-500 w-[266px]" />
            <p>New interview user has been added. Click Submit to go back.</p>
            <button onClick={handleClose} className="flex text-[18px] font-bold justify-center items-center py-[13px] px-[20px] bg-green-500 text-white min-w-[122px] rounded-md max-md:flex-1 hover:opacity-70">
                Submit
            </button>
        </div>
    </ModalTemplate>
  )
}
