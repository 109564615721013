import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

import { DefaultLayout } from "../layouts/DefaultLayout";
import { JobLayout } from "../layouts/JobLayout";

import LoginPage from '../pages/HOM1_6.jsx';
import SUP01 from '../pages/SUP01.jsx';
import SUP01_2 from '../pages/SUP01_2.jsx';
import SUP01_2_1 from "../pages/SUP01_2_1.jsx";
import SUP01_2_1_1 from "../pages/SUP01_2_1_1.jsx";
import SUP01_2_1_2 from "../pages/SUP01_2_1_2.jsx";
import SUP01_3 from '../pages/SUP01_3.jsx';
import SUP01_3_1_1 from '../pages/SUP01_3_1_1.jsx';
import SUP01_3_1_2 from '../pages/SUP01_3_1_2.jsx';
import SUP01_4 from '../pages/SUP01_4.jsx';
import SUP01_4_1_1 from '../pages/SUP01_4_1_1.jsx';
import SUP1_1 from "../pages/SUP1_1.jsx";
import SUP1_1_1 from "../pages/SUP1_1_1.jsx";
import SUP1_1_2 from "../pages/SUP1_1_2.jsx";
import SUP1_5_1 from "../pages/SUP1_5_1.jsx";
import SUP1_5_2 from "../pages/SUP1_5_2.jsx";
import HOM1_1 from "../pages/HOM1_1.jsx";
import HOM1_10_1 from '../pages/HOM1_10_1.jsx';
import HOM1_14 from '../pages/HOM1_14.jsx';
import HOM1_15 from '../pages/HOM1_15.jsx';
import HOM1_15_1 from '../pages/HOM1_15_1.jsx';
import HOM1_2 from "../pages/HOM1_2.jsx";
import HOM1_4 from "../pages/HOM1_4.jsx";
import HOM1_5_1 from '../pages/HOM1_5_1.jsx';
import HOM1_6_1 from '../pages/HOM1_6_1.jsx';
import HOM1_7 from '../pages/HOM1_7.jsx';
import HOM1_7_1 from '../pages/HOM1_7_1.jsx';
import HOM1_8 from '../pages/HOM1_8.jsx';
import HOM_1_10 from "../pages/HOM_1_10.jsx";
import HOM_1_11 from "../pages/HOM_1_11.jsx";
import HOM_1_12 from "../pages/HOM_1_12.jsx";
import HOM_1_13 from "../pages/HOM_1_13.jsx";
import SBM01_1 from "../pages/SBM01_1.jsx";
import SBM01_2_1 from "../pages/SBM01_2_1.jsx";
import SBM04_1 from "../pages/SBM04_1.jsx";
import SBM04_1_1 from "../pages/SBM04_1_1.jsx";
import SBM04_2 from "../pages/SBM04_2.jsx";
import SBM04_2_1 from "../pages/SBM04_2_1.jsx";
import SBM04_3_1 from "../pages/SBM04_3_1.jsx";
import SBM04_4 from "../pages/SBM04_4.jsx";
import SBM04_4_1 from "../pages/SBM04_4_1.jsx";

import TermsPrivacyLayout from '../layouts/TermsPrivacyLayout.jsx';
import AP_15 from '../pages/AP_15.jsx';
import AP_37 from '../pages/AP_37.jsx';
import AP_38 from '../pages/AP_38.jsx';
import AP_38_1 from '../pages/AP_38_1.jsx';
import AP_39 from '../pages/AP_39.jsx';
import AP_44 from '../pages/AP_44.jsx';
import AP_46 from '../pages/AP_46.jsx';
import SBM01_10_1_1 from '../pages/SBM01_10_1_1.jsx';
import SBM01_10_1_5 from '../pages/SBM01_10_1_5.jsx';
import SBM01_2 from "../pages/SBM01_2.jsx";
import SBM01_2_3 from "../pages/SBM01_2_3.jsx";
import SBM04 from "../pages/SBM04.jsx";
import SBM04_3 from '../pages/SBM04_3.jsx';

import AP_01 from '../pages/AP_01.jsx';
import HOM1_8_1 from '../pages/HOM1_8_1.jsx';
import SBM01 from '../pages/SBM01.jsx';
import SBM01_10 from '../pages/SBM01_10.jsx';
import SBM01_10_1 from '../pages/SBM01_10_1.jsx';
import SBM01_10_14_2 from '../pages/SBM01_10_14(2).jsx';
import SBM01_10_14 from '../pages/SBM01_10_14.jsx';
import SBM01_10_14_2_1 from '../pages/SBM01_10_14_2_1.jsx';
import SBM01_10_14_2_12 from '../pages/SBM01_10_14_2_12.jsx';
import SBM01_10_14_2_2 from '../pages/SBM01_10_14_2_2.jsx';
import SBM01_10_14_2_3 from '../pages/SBM01_10_14_2_3.jsx';


import SBM01_10_15 from '../pages/SBM01_10_15.jsx';
import SBM01_10_16 from '../pages/SBM01_10_16.jsx';
import SBM01_10_18 from '../pages/SBM01_10_18.jsx';
import SBM01_10_1_a from '../pages/SBM01_10_1_a.jsx';
import SBM01_10_1_b from '../pages/SBM01_10_1_b.jsx';
import SBM01_10_2 from '../pages/SBM01_10_2.jsx';
import SBM01_10_3 from '../pages/SBM01_10_3.jsx';
import SBM01_10_3_1 from '../pages/SBM01_10_3_1.jsx';
import SBM01_10_3_5 from '../pages/SBM01_10_3_5.jsx';
import SBM01_10_4 from '../pages/SBM01_10_4.jsx';
import SBM01_10_5 from '../pages/SBM01_10_5.jsx';
import SBM01_10_6 from '../pages/SBM01_10_6.jsx';
import SBM01_10_7 from '../pages/SBM01_10_7.jsx';
import SBM01_10_8 from '../pages/SBM01_10_8.jsx';
import SBM02_1_1 from '../pages/SBM02_1_1.jsx';
import SBM02_1_2 from '../pages/SBM02_1_2.jsx';
import SBM02_1_4 from '../pages/SBM02_1_4.jsx';
import SBM02_2 from '../pages/SBM02_2.jsx';
import SBM02_2_1 from '../pages/SBM02_2_1.jsx';
import SBM02_2_1_1 from '../pages/SBM02_2_1_1.jsx';
import SBM02_3_1 from '../pages/SBM02_3_1.jsx';
import SBM02_3_1_1 from '../pages/SBM02_3_1_1.jsx';
import SBM02_4_1 from '../pages/SBM02_4_1.jsx';
import SBM02_4_1_1 from '../pages/SBM02_4_1_1.jsx';
import SBM02_5 from '../pages/SBM02_5.jsx';
import SBM02_5_1 from '../pages/SBM02_5_1.jsx';
import SBM02_5_1_1 from '../pages/SBM02_5_1_1.jsx';
import SBM02_6_1 from '../pages/SBM02_6_1.jsx';
import SBM02_6_1_1 from '../pages/SBM02_6_1_1.jsx';
import SBM02_7_2_1 from '../pages/SBM02_7_2_1.jsx';
import SBM02_7_2_1_1 from '../pages/SBM02_7_2_1_1.jsx';
import SBM02_7_3_1 from '../pages/SBM02_7_3_1.jsx';
import SBM02_7_3_1_1 from '../pages/SBM02_7_3_1_1.jsx';
import SBM02_7_4_1 from '../pages/SBM02_7_4_1.jsx';
import SBM02_7_4_1_1 from '../pages/SBM02_7_4_1_1.jsx';
import SBM02_7_5_1 from '../pages/SBM02_7_5_1.jsx';
import SBM02_7_5_1_1 from '../pages/SBM02_7_5_1_1.jsx';
import SBM02_7_6_1 from '../pages/SBM02_7_6_1.jsx';
import SBM02_7_6_1_1 from '../pages/SBM02_7_6_1_1.jsx';
import SBM03 from '../pages/SBM03.jsx';
import SBM03_1 from '../pages/SBM03_1.jsx';
import SBM05_ADM from '../pages/SBM05_ADM.jsx';
import SBM05_INT from '../pages/SBM05_INT.jsx';
import SBM05_TEA from '../pages/SBM05_TEA.jsx';

import ProtectedRouteLayout from '../layouts/ProtectedRouteLayout.jsx';
import PublicHomeEditorLayout from '../layouts/PublicHomeEditorLayout.jsx';
import { AP_01_Container } from "../pages/AP_01_Form/AP_01_Container";
import HOM_pages_template from '../pages/pageEditor/HOM_pages_template.jsx';
import SBM02 from '../pages/SBM02.jsx';
// import SBM02_3_dep from '../pages/SBM02_3_dep.jsx';
import SBM02_4_V2 from '../pages/SBM02_4-V2.jsx';
// import SBM02_4_dep from '../pages/SBM02_4_dep.jsx';
import SBM02_5_new from '../pages/SBM02_5/SBM02_5_new.jsx';
import SBM02_6 from "../pages/SBM02_6";
import SBM02_7_1 from "../pages/SBM02_7_1";
import SBM02_7_2 from '../pages/SBM02_7_2.jsx';
import SBM02_7_3 from "../pages/SBM02_7_3";
import SBM02_7_4 from '../pages/SBM02_7_4.jsx';
import SBM02_7_4_V2 from "../pages/SBM02_7_4_V2";
import SBM02_7_5 from '../pages/SBM02_7_5.jsx';
import SBM02_7_6 from '../pages/SBM02_7_6.jsx';
import SBM02_7_7 from '../pages/SBM02_7_7.jsx';
import SBM02_8 from "../pages/SBM02_8";
import SBM05_1 from '../pages/SBM05_1.jsx';
import SBM05_1_1 from '../pages/SBM05_1_1.jsx';
import SBM05_2 from '../pages/SBM05_2.jsx';
import SBM05_3 from '../pages/SBM05_3.jsx';
import WrapperHOM1_5 from "../pages/WrapperHOM1_5";
import PublicPageLayout from '../layouts/PublicPageLayout.jsx';
import { LoginedLayout } from "../layouts/LoginedLayout";
import SUP01_3_1 from "../pages/SUP01_3_1";
import SUP01_4_1 from "../pages/SUP01_4_1";

import NewJobDetails from "../pages/NewJobDetails/NewJobDetails";
import JobEdit from "../pages/JobEdit/JobEdit";
import { MainContainerSBM01 } from "../pages/SBM01_Form/MainContainerSBM01";
import { StepOne } from '../pages/AP_01_Form/steps/StepOne.jsx';
import JobApplicationLayout from '../layouts/JobApplicationLayout.jsx';
import { ReportCardPages } from '../pages/SBM01/ReportCard/index.js';
import { HistoryPages } from '../pages/SBM01/History/index.js';
import SBM01_10_14_4 from '../pages/SBM01/Reference';
import ConfirmEmailLayout from '../layouts/ConfirmEmailLayout';
import { HistoryLayout } from '../pages/SBM01/layouts/HistoryLayout';
import { ReportCardLayoutNew } from '../pages/SBM01/layouts/ReportCardLayoutNew';
import SignLetter from '../pages/SignLetter';
import ReviewDocuments from '../pages/ReviewDocuments';
import SBM01_10_14_3 from '../pages/SBM01/OfferLetter';
import OfferTemplates from '../pages/OfferTemplates';
import VideoInterview from '../pages/VideoInterview';
import PostInterview from '../pages/PostInterview';
import AP_hiring from '../pages/AP_hiring';
import SBM01_2_2 from '../pages/SBM01_2_2';
import SBM02_3with2_4 from "../pages/SBM02_3with2_4";


export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<PublicPageLayout />}>
            <Route path='/confirm-team-member/:token' element={<ConfirmEmailLayout />} />
            <Route element={<DefaultLayout unlimitWidth fullBg pb="pb-0" />} >
                <Route path="/HOM_1_10" element={<HOM_1_10 />} />
            </Route>

            <Route path="/" element={<HOM1_1 />} />

            <Route element={<DefaultLayout />} >
                {/* <Route path="/HOM1_5_1" element={<HOM1_5_1 />}/> */}
                {/* <Route path="/HOM1_10_1" element={<HOM1_10_1 />} /> */}
                <Route path="/HOM1_7" element={<HOM1_7 />} />

                <Route path="/SUP1_5_2" element={<SUP1_5_2 />} />
            </Route>

            <Route element={<DefaultLayout fullBg />} >
                <Route element={<ProtectedRouteLayout />}>
                    <Route path="/SUP1_5_1" element={<SUP1_5_1 />} />
                </Route>
                <Route path="/HOM1_7_1-bkp" element={<HOM1_7_1 />} />
            </Route>

            <Route element={<TermsPrivacyLayout />} >
                <Route path="/HOM_1_11" element={<HOM_1_11 />} />
                <Route path="/HOM_1_12" element={<HOM_1_12 />} />
                <Route path="/HOM_1_13" element={<HOM_1_13 />} />

            </Route>

            <Route element={<DefaultLayout fullBg unlimitWidth />} >
                <Route path="/HOM1_8" element={<HOM1_8 />} />
            </Route>




            <Route element={<DefaultLayout fullBg unlimitWidth pb="pb-[0px]" />} >
                <Route path="/HOM1_5" element={<WrapperHOM1_5 />} />
                <Route path="/HOM1_4" element={<HOM1_4 />} />
                <Route element={<ProtectedRouteLayout />}>
                    <Route path="/SBM03" element={<SBM03 />} />
                </Route>
            </Route>
            <Route element={<DefaultLayout fullHeight pb="pb-[0px]" />} >
                <Route path="/HOM1_5_1" element={<HOM1_5_1 />} />
                <Route path="/HOM1_10_1" element={<HOM1_10_1 />} />

            </Route>
            <Route element={<DefaultLayout fullHeight bgStyle='secondary' headerStyle='black' pb="pb-[0px]" />} >
                <Route path="/HOM1_6" element={<LoginPage />} />
            </Route>
            <Route element={<DefaultLayout fullHeight unlimitWidth bgStyle='secondary' headerStyle='black' pb="pb-[0px]" />} >
                <Route path="/HOM-1_6_1" element={<HOM1_6_1 />} />
                <Route path="/HOM-1_15_1" element={<HOM1_15_1 />} />
                <Route path="/HOM1_15" element={<HOM1_15 />} />
            </Route>

            <Route element={<ProtectedRouteLayout />}>
                <Route element={<LoginedLayout />} >

                    <Route path='/SBM01_form' element={<MainContainerSBM01 />} />

                    <Route path="/new-job" element={<NewJobDetails />} />

                    <Route path="/SUP1_1_1" element={<SUP1_1_1 />} />
                    <Route path="/SUP1_1_2" element={<SUP1_1_2 />} />
                    <Route path="/SUP1_1" element={<SUP1_1 />} />
                    <Route path="/SUP01_2_1_1" element={<SUP01_2_1_1 />} />
                    <Route path="/SUP01_2_1_2" element={<SUP01_2_1_2 />} />
                    <Route path="/SUP01_2_1/:id?" element={<SUP01_2_1 />} />
                    <Route path="/SUP01_2" element={<SUP01_2 />} />
                    <Route path="/SUP01_3" element={<SUP01_3 />} />
                    <Route path="/SUP01_4" element={<SUP01_4 />} />
                    <Route path="/SUP01" element={<SUP01 />} />
                    <Route path="/SUP01_3_1_1" element={<SUP01_3_1_1 />} />
                    <Route path="/SUP01_3_1_2" element={<SUP01_3_1_2 />} />
                    <Route path="/SUP01_3_1/:id?" element={<SUP01_3_1 />} />
                    <Route path="/SUP01_4_1_1" element={<SUP01_4_1_1 />} />
                    <Route path="/SUP01_4_1" element={<SUP01_4_1 />} />
                    <Route path="/SBM04_2" element={<SBM04_2 />} />
                    <Route path="/SBM04_1_1" element={<SBM04_1_1 />} />
                    <Route path="/SBM01_2/:jobId" element={<SBM01_2 />} />
                    <Route path="/SBM01_3" element={<SBM01_2 />} />
                    <Route path="/SBM04_1" element={<SBM04_1 />} />
                    <Route path="/SBM04_2_1" element={<SBM04_2_1 />} />
                    <Route path="/SBM04_3_1" element={<SBM04_3_1 />} />
                    <Route path="/SBM04_4_1" element={<SBM04_4_1 />} />
                    <Route path="/SBM04_4" element={<SBM04_4 />} />
                    <Route path="/SBM04_3" element={<SBM04_3 />} />
                    <Route path="/SBM04" element={<SBM04 />} />
                    <Route path="/SBM01_2_3/:jobId/:applicationId" element={<SBM01_2_3 />} />
                    <Route path="/SBM01_1" element={<SBM01_1 />} />
                    <Route path="/SBM01_2_1/:jobId/:applicationId" element={<SBM01_2_1 />} />
                    <Route path="/SBM01_2_2/:jobId/:applicationId" element={<SBM01_2_2 />} />
                    <Route path="/SBM01_10_1_1" element={<SBM01_10_1_1 />} />
                    <Route path="/SBM01_10_1_5" element={<SBM01_10_1_5 />} />
                    <Route path="/SBM01_10_1_a" element={<SBM01_10_1_a />} />
                    <Route path="/SBM01_10_1_b" element={<SBM01_10_1_b />} />
                    {/* <Route path="/SBM01_10_4/:jobId/:applicationId" element={<SBM01_10_4 />} /> */}
                    {/* <Route path="/SBM01_10_5/:jobId/:applicationId" element={<SBM01_10_5 />} /> */}
                    {/* <Route path="/SBM01_10_1/:jobId/:applicationId" element={<SBM01_10_1 />} /> */}
                    {/* <Route path="/SBM01_10_2/:jobId/:applicationId" element={<SBM01_10_2 />} /> */}
                    <Route path="/SBM01_10_3_1" element={<SBM01_10_3_1 />} />
                    <Route path="/SBM01_10_3_5" element={<SBM01_10_3_5 />} />
                    {/* <Route path="/SBM01_10_3/:jobId/:applicationId" element={<SBM01_10_3 />} /> */}
                    {/* <Route path="/SBM01_10_6/:jobId/:applicationId" element={<SBM01_10_6 />} /> */}
                    {/* <Route path="/SBM01_10_7/:jobId/:applicationId" element={<SBM01_10_7 />} /> */}

                    <Route path="/SBM01_10_14" element={<SBM01_10_14 />} />
                    <Route path="/SBM01_10_14(2)/:jobId/:applicationId" element={<SBM01_10_14_2 />} />
                    {/* <Route path="/SBM01_10_14(3)" element={<SBM01_10_14_v3 />} /> */}
                    {/* <Route path="/SBM01_10_8/:jobId/:applicationId" element={<SBM01_10_8 />} /> */}
                    <Route path="/SBM01_10" element={<SBM01_10 />} />
                    {/* <Route path="/SBM01_10_14_2_1/:jobId/:applicationId" element={<SBM01_10_14_2_1 />} /> */}
                    {/* <Route path="/SBM01_10_14_2_2" element={<SBM01_10_14_2_2 />} /> */}
                    {/* <Route path="/SBM01_10_14_2_12" element={<SBM01_10_14_2_12 />} /> */}
                    <Route path="/SBM02_2_1" element={<SBM02_2_1 />} />
                    <Route path="/SBM02_2_1_1" element={<SBM02_2_1_1 />} />
                    <Route path="/SBM02_3_1" element={<SBM02_3_1 />} />
                    <Route path="/SBM02_4_1" element={<SBM02_4_1 />} />
                    <Route path="/SBM02_5_1" element={<SBM02_5_1 />} />
                    <Route path="/SBM02_6_1" element={<SBM02_6_1 />} />
                    <Route path="/SBM02_7_2_1" element={<SBM02_7_2_1 />} />
                    <Route path="/SBM02_7_3_1" element={<SBM02_7_3_1 />} />
                    <Route path="/SBM02_7_4_1" element={<SBM02_7_4_1 />} />
                    <Route path="/SBM02_7_5_1" element={<SBM02_7_5_1 />} />
                    <Route path="/SBM02_7_6_1" element={<SBM02_7_6_1 />} />
                    <Route path="/SBM02_3_1_1" element={<SBM02_3_1_1 />} />
                    <Route path="/SBM02_4_1_1" element={<SBM02_4_1_1 />} />
                    <Route path="/SBM02_5_1_1" element={<SBM02_5_1_1 />} />
                    <Route path="/SBM02_6_1_1" element={<SBM02_6_1_1 />} />
                    <Route path="/SBM02_7_2_1_1" element={<SBM02_7_2_1_1 />} />
                    <Route path="/SBM02_7_3_1_1" element={<SBM02_7_3_1_1 />} />
                    <Route path="/SBM02_7_4_1_1" element={<SBM02_7_4_1_1 />} />
                    <Route path="/SBM02_7_5_1_1" element={<SBM02_7_5_1_1 />} />
                    <Route path="/SBM02_7_6_1_1" element={<SBM02_7_6_1_1 />} />
                    <Route path="/SBM02_5-bkp" element={<SBM02_5 />} />
                    <Route path="/SBM02_7_5" element={<SBM02_7_5 />} />
                    <Route path="/SBM02_5" element={<SBM02_5_new />} />


                    <Route path="/SBM01" element={<SBM01 />} />
                    <Route path="/SBM02_1_1" element={<SBM02_1_1 />} />
                    <Route path="/SBM02_1_2" element={<SBM02_1_2 />} />
                    <Route path="/SBM02_1_4" element={<SBM02_1_4 />} />
                    {/* <Route path="/SBM01_10_14_2_3" element={<SBM01_10_14_2_3 />} /> */}
                    <Route path="/SBM01_10_14_4/:jobId/:applicationId" element={<SBM01_10_14_4 />} />
                    <Route path="/SBM01_10_15" element={<SBM01_10_15 />} />
                    {/*<Route path="/SBM01_10_15" element={<SBM01_10_15_new />} />*/}
                    {/* <Route path="/SBM01_10_16/:jobId/:applicationId" element={<SBM01_10_16 />} /> */}
                    <Route path="/SBM02_2" element={<SBM02_2 />} />

                    {/* ReportCardPages { */}
                    <Route element={<ReportCardLayoutNew />}>
                        <Route path="/SBM01_10_18/:jobId/:applicationId" element={<ReportCardPages.Clarifications />} />
                        <Route path="/SBM01_10_1/:jobId/:applicationId" element={<ReportCardPages.FirstInterview />} />
                        <Route path="/SBM01_10_2/:jobId/:applicationId" element={<ReportCardPages.PostFirstInterviewSurvey />} />
                        <Route path="/SBM01_10_3/:jobId/:applicationId" element={<ReportCardPages.SecondInterview />} />
                        <Route path="/SBM01_10_4/:jobId/:applicationId" element={<ReportCardPages.DocumentReview />} />
                        <Route path="/SBM01_10_16/:jobId/:applicationId" element={<ReportCardPages.RatingOfTheJob />} />
                        <Route path="/scores/:jobId/:applicationId" element={<ReportCardPages.Scores />} />
                        <Route path="/SBM01_10_5/:jobId/:applicationId" element={<ReportCardPages.AIRecommendation />} />
                        <Route path="/SBM01_10_5_5/:jobId/:applicationId" element={<ReportCardPages.FinalHiringDecision />} />
                        <Route path="/SBM01_10_6/:jobId/:applicationId" element={<ReportCardPages.TrainingInsights />} />
                        <Route path="/SBM01_10_8/:jobId/:applicationId" element={<ReportCardPages.TrainingMilestones />} />
                        <Route path="/SBM01_10_7/:jobId/:applicationId" element={<ReportCardPages.OfferMeeting />} />
                    </Route>

                    {/* } ReportCardPages  */}

                    {/* HistoryPages { */}
                    <Route element={<HistoryLayout />}>
                        <Route path="/SBM01_10_14_2_1/:jobId/:applicationId" element={<HistoryPages.Application />} />
                        <Route path="/SBM01_10_14_2_2/:jobId/:applicationId" element={<HistoryPages.Communications />} />
                        <Route path="/SBM01_10_14_2_3/:jobId/:applicationId" element={<HistoryPages.Assessment />} />
                        <Route path="/SBM01_10_14_2_12/:jobId/:applicationId" element={<HistoryPages.Resume />} />

                    </Route>
                    <Route element={<HistoryLayout isOfferLetter={true}/>}>
                        <Route path="/SBM01_10_14_3/:jobId/:applicationId" element={<SBM01_10_14_3 />} />
                    </Route>
                    {/* } HistoryPages */}

                    {/* <Route path="/SBM01_10_18/:jobId/:applicationId" element={<SBM01_10_18 />} /> */}
                    <Route path="/SBM05_TEA" element={<SBM05_TEA />} />
                    <Route path="/SBM05_INT" element={<SBM05_INT />} />
                    <Route path="/SBM05_ADM" element={<SBM05_ADM />} />
                    <Route path="/jobs-setup" element={<SBM02 />} />
                    <Route path="/SBM05_1_1" element={<SBM05_1_1 />} />
                    <Route path="/offer-templates" element={<OfferTemplates />} />
                    <Route path="/SBM05_1/:memberId" element={<SBM05_1 />} />
                    <Route path="/SBM05_2" element={<SBM05_2 />} />
                    <Route path="/SBM05_3" element={<SBM05_3 />} />
                    {/* <Route path="/SBM02_7_7" element={<SBM02_7_7 />} /> */}
                    {/* <Route path="/SBM02_7_6" element={<SBM02_7_6 />} /> */}
                    <Route path="/SBM02_3with2_4" element={<SBM02_3with2_4 />} />
                    <Route path="/SBM02_4_V2" element={<SBM02_4_V2 />} />
                    {/*<Route path="/SBM02_4_dep" element={<SBM02_4_dep />} />*/}
                    <Route path="/SBM02_7_2" element={<SBM02_7_2 />} />
                    {/* <Route path="/SBM02_7_4" element={<SBM02_7_4 />} /> */}
                    <Route path="/SBM02_7_4_V2" element={<SBM02_7_4_V2 />} />
                    {/* <Route path="/SBM02_7_3" element={<SBM02_7_3 />} /> */}
                    <Route path="/SBM02_6" element={<SBM02_6 />} />
                    <Route path="/SBM02_7_1" element={<SBM02_7_1 />} />
                    <Route path="/job-edit/:id" element={<JobEdit />} />
                    <Route path="/job-edit/:id/:step" element={<JobEdit />} />
                </Route>
            </Route>
            {/* <Route path="/HOM1_6" element={<LoginPage />} /> */}
            {/* <Route path="/HOM1_6_1" element={<HOM1_6_1 />} /> */}
            {/* <Route path="/HOM1_15_1" element={<HOM1_15_1 />} /> */}
            {/* <Route path="/HOM1_15" element={<HOM1_15 />} /> */}

            {/* <Route element={<DefaultLayout />} >
                <Route path="/HOM1_1" element={<HOM1_1 />}/>
            </Route> */}



            <Route path="/HOM1_1" element={<HOM1_1 />} />
            <Route path="/HOM1_2" element={<HOM1_2 />} />

            <Route path="/HOM1_14/:id" element={<HOM1_14 />} />
            <Route path="/HOM1_8_1" element={<HOM1_8_1 />} />
            <Route path="/SBM03_1/:id" element={<SBM03_1 />} />



            <Route element={<JobLayout />}>
                <Route path="/review-documents/:applicationId/:token" element={<ReviewDocuments />} />
                <Route path="/post-interview/:applicationId/:token" element={<PostInterview />} />
                <Route path="/sign-offer-letter/:token" element={<SignLetter />} />
                <Route path="/video-interview/:candidateId/:jobApplicationId" element={<VideoInterview />} />
                <Route path="/AP_01/:id" element={<AP_01 />} />
                <Route path="/SBM02_8" element={<SBM02_8 />} />
                <Route path="/AP_15" element={<AP_15 />} />
                <Route path="/AP_37" element={<AP_37 />} />
                <Route path="/AP_38_1" element={<AP_38_1 />} />
                <Route path="/AP_38" element={<AP_38 />} />
                <Route path="/AP_39" element={<AP_39 />} />
                <Route path="/AP_44" element={<AP_44 />} />
                <Route path="/AP_46" element={<AP_46 />} />

                <Route element={<JobApplicationLayout />}>
                    <Route path="/job/:id" element={<AP_01 />} />
                    <Route path="/job/:id/it-takes-x-minutes" element={<AP_15 />} />
                    <Route path="/job/:id/consent" element={<AP_37 />} />
                    <Route path="/job/:id/you!" element={<AP_38 />} />
                    <Route path="/job/:id/critical-requirement" element={<AP_39 />} />
                    <Route path="/job/:id/hiring-assesment" element={<AP_hiring />} />
                    <Route path="/job/:id/you-must-submit-your-resume-here" element={<AP_44 />} />
                    <Route path="/job/:id/the-next-step" element={<AP_46 />} />
                </Route>

            </Route>


            <Route element={<ProtectedRouteLayout />}>
                <Route element={<LoginedLayout />} >
                    <Route path='/SUP01_4_1' element={<PublicHomeEditorLayout />}>
                        <Route path=':id' element={<HOM_pages_template />} />
                    </Route>
                </Route>
            </Route>
        </Route>
    ),
)

export default router
