import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from "../components/parts/Header";
import { Footer } from "../components/parts/Footer";

export const DefaultLayout = ({ fullBg = false, fullHeight = false, unlimitWidth = false, pb = false, headerStyle = "", bgStyle = "" }) => {
    const bgSecondatyImageUrl = "https://cdn.builder.io/api/v1/image/assets/TEMP/bc2cb4c50cf199608bfab0e75f8ca7f769b945ec31851971a5de51bc04af22f1?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&";
    const bgDefaultImageUrl = "https://cdn.builder.io/api/v1/image/assets%2F9106ba04c02e4dceae488e75e2ac4862%2F14a2333ed5504c789df293d766cde515";

    return (
        <>
            <div className="box-border flex relative flex-col shrink-0 min-h-[100dvh]">
                <Header colorStyle={headerStyle} />
                <main className={`flex relative flex-col flex-auto pt-6 lg:pt-[40px] z-[1] w-full ${pb ? pb : fullBg ? 'pb-[100px]' : 'pb-[300px]'} ${unlimitWidth ? '' : 'max-w-[1272px] px-4 mx-auto'}`}>
                    <div className={`flex flex-col flex-auto ${fullBg ? "" : "mb-[-200px]"} ${fullHeight ? "!mb-0" : ""}`}>
                        <Outlet />
                    </div>
                </main>
                <Footer />
                {/* <div
                    className={`
                        box-border flex absolute left-0 z-0 self-stretch w-full bg-center bg-no-repeat bg-cover
                        bg-[url(${bgStyle === "secondary" ? bgSecondatyImageUrl : bgDefaultImageUrl })] h-[${fullBg ? 'full' : '968px'}]
                        ${fullHeight ? "!h-full" : ""}
                        `}
                    style={{ zIndex: "-1" }}
                /> */}
                <img
                src={`${bgStyle === "secondary" ? bgSecondatyImageUrl : bgDefaultImageUrl }`}
                    className={`
                        box-border flex absolute left-0 z-0 self-stretch w-full bg-center bg-no-repeat bg-cover
                        h-[${fullBg ? 'full' : '968px'}]
                        ${fullHeight ? "!h-full" : ""}
                        `}
                    style={{ zIndex: "-1" }}
                />
            </div>
        </>
    )
}
