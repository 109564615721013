import React, { useState } from 'react';
import InputField from "../../components/base/InputField";
import SelectField from "../../components/base/SelectField";
import Checkbox from '../../components/base/Checkbox';
import TextareaField from '../../components/base/TextareaField';
import magicWandIcon from "../../assets/img/magic-wand.svg";

import Step1 from "./Step1";
import Step2 from "./Step2";

const Step3 = ({ payload, setPayload, industriesList }) => {
    const [formData, setFormData] = useState({
        jobTitle: '',
        manageRole: '',
        employeeType: '',
        country: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
    });
    const manageRoles = [
        { value: "no", label: "No - will not manage other employees" }
    ];
    const employeeTypes = [
        { value: "full_time", label: "Full Time - Salary" }
    ];
    const countries = [
        { value: "united_states", label: "United States" }
    ];
    const cities = [
        { value: "chicago", label: "Chicago" }
    ];
    const states = [
        { value: "illionis", label: "Illionis" }
    ];
    const zipCodes = [
        { value: "76567", label: "76567" }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({ ...payload, [name]: value });
    };
    
    return (
        <>
            <div className="flex flex-col items-center mt-10 w-full">
                <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                    <div className="text-4xl font-bold text-zinc-800">Preview</div>
                    <p className='max-w-[783px] mx-auto'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. </p>
                </div>
                <div className="flex flex-col px-[60px] py-[50px] text-lg w-full bg-white rounded-md border border-solid border-zinc-300 max-md:px-[32px] max-md:py-[36px] max-md:py-[20px]">
                    <Step1
                        inComponent={true}
                        payload={payload}
                        setPayload={setPayload}
                        industriesList={industriesList}
                    />
                    <Step2
                        inComponent={true}
                        payload={payload}
                        setPayload={setPayload}
                    />
                </div>
            </div>
        </>
    )
}

export default Step3