import React from "react";
import { useState } from "react";
import ModalTemplate from '../base/ModalTemplate';
import InputFieldHorisontal from '../base/InputFieldHorisontal';
import Button from "../base/Button";
import TextareaFieldHorisontal from '../base/TextareaFieldHorisontal'
import SelectBox from "../base/SelectBox";
import api from "../../api";
import { useSelector } from "react-redux";
import { getUser, getUserCompanyId } from "../../redux/slices/applicationSlice";
import { State } from "country-state-city";
import InputField from "../base/InputField";
import { toast } from "react-toastify";
import PageLoader from "../PageLoader";
const AddUserModal = ({ open, handleClose, footerBorderTop = true, role, onSubmitClick, addItem }) => {
    const companyId = useSelector(getUserCompanyId)
    const [formData, setFormData] = useState({
        // "role": "interviewer",
        "email": "",
        "firstName": "",
        "lastName": "",
        "addressLine": "",
        "city": "",
        "state": "",
        "zip": "",
        "phone": "",
        "password": "",
        passwordConfirm: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const user = useSelector(getUser);
    const handleSubmit = async () => {
        setIsLoading(true)
        const response = await api.companyTeam.addUserInCompany(companyId, {
            ...formData,
            role
        });
        setIsLoading(false)
        console.log(response)
        if (response.success) {
            addItem({ ...response.data, role})
            handleClose();
            onSubmitClick()
        }
        else {
            if (response?.errors?.length) {
                response.errors.forEach((error) => {
                    toast.error(`${error?.field}: ${error?.msg}`)
                })
            } else {
                toast.error(response?.message)
            }
        }
    }
    const states = State.getStatesOfCountry('US')?.map(item => ({ value: item.isoCode, label: item.name }));
    return (
        <ModalTemplate open={open} onClick={handleClose}>
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">
                    {role === 'interviewer' && 'Add New Interview User'}
                    {role === 'member' && 'Add New Team User'}
                </h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            <div className="modal__body">
                <div className="w-full">
                    <div className="flex flex-col gap-[16px] mb-[32px]">
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Name"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="firstName" id="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="John"
                            afterInput={<input
                                type={'text'}
                                id={'lastName'}
                                name={'lastName'}
                                className={`flex-auto rounded-md min-h-[46px] text-[16px] px-[16px] focus:outline-none focus:ring-2 focus:ring-green-500 bg-white rounded-md border border-solid border-zinc-300 w-full`}
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder={'Doe'}
                            />}
                        />

                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Email"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="email" id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="123-453-1232"
                        />
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Password"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="password" name="password" id="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="************"
                        />
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Confirm Password"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="password" name="passwordConfirm" id="passwordConfirm"
                            value={formData.passwordConfirm}
                            onChange={handleChange}
                            placeholder="************"
                        />


                    </div>
                    <div className="flex flex-col gap-[16px] mb-[32px]">
                        <h3 className="text-[20px] font-semibold">Address</h3>
                        <div className="flex max-md:flex-col md:items-start gap-[12px] md:gap-[16px] flex-1">
                            <label className="flex items-center text-[16px] min-w-[140px] text-[16px] font-semibold text-zinc-800 min-h-[46px] leading-[18px]">Address</label>
                            <div className="flex flex-col gap-[8px] flex-auto">
                                <InputFieldHorisontal
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    type="text" name="addressLine" id="addressLine"
                                    value={formData.addressLine}
                                    onChange={handleChange}
                                    placeholder="Address line 1..."
                                />
                                <InputFieldHorisontal
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    type="text" name="city" id="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder="City"
                                />
                                <SelectBox
                                    options={states} svg={2}
                                    value={formData.state}
                                    onSelect={(value) => handleChange({
                                        target: {
                                            name: 'state',
                                            value
                                        }
                                    })}
                                    placeholder='State'
                                />
                                <InputFieldHorisontal
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    type="text" name="zip" id="zip"
                                    value={formData.zip}
                                    onChange={handleChange}
                                    placeholder="Zip Code"
                                />
                            </div>
                        </div>
                        <InputFieldHorisontal
                            inputBg="bg-white rounded-md border border-solid border-zinc-300"
                            label="Phone"
                            labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                            type="text" name="phone" id="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                        />
                    </div>
                </div>
            </div>
            <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                <Button className={'text-gray-400 hover:opacity-70 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'} onClick={handleClose} >
                    <span className="underline" >Cancel</span>
                </Button>
                <Button onClick={handleSubmit} className={'bg-green-500 hover:opacity-70 text-white max-md:w-full min-w-[122px] h-[52px] text-[18px]'} >
                    {isLoading ? <PageLoader className='h-[40px] my-auto' /> :
                        <span >Submit</span>}
                </Button>
            </div>
        </ModalTemplate>
    )
};

export default AddUserModal