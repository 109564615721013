
import { Link } from "react-router-dom";
const defaultCrumbs = ['EJ Support', 'Subscriber Account Page', 'Account De-Active Confirmation Page'];

export default function BreadCrumbWhite({ crumbs = defaultCrumbs, statusSelect = false, interviewUser = false, teamUser = false, onInterviewUserClick, onTeamUserClick }) {
    const renderCrumbs = () => {
        return crumbs.map((crumb, index) => {

            if (Array.isArray(crumb)) {
                const [text, link, onclickfunc] = crumb
                return (
                    <span className={index === crumbs.length - 1 ? 'text-[#37B34A]' : 'text-zinc-800'} key={index}>
                        {onclickfunc ?
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                onclickfunc()
                            }}
                                className="hover:text-[#37B34A]">
                                {text}
                            </a>
                            : index !== crumbs.length - 1 ? link ?
                                <Link to={link} className="hover:text-[#37B34A]">{text}</Link>
                                : <span className='cursor-pointer hover:text-[#37B34A]'>{text}</span>
                                : text
                        }
                        {index !== crumbs.length - 1 && <span className="mr-2"> / </span>}
                    </span>)
            }
            return (
                <span className={index === crumbs.length - 1 ? 'text-[#37B34A]' : 'text-zinc-800'} key={index}>
                    {
                        index !== crumbs.length - 1 ?
                            <a href="/SBM01" className="hover:text-[#37B34A]">{crumb}</a>
                            : crumb
                    }
                    {index !== crumbs.length - 1 && <span className="mr-2"> / </span>}
                </span>
            )
        });
    };

    return (

        <div className="lg:py-[50px] px-[22px] py-[24px] w-full bg-neutral-100 md:max-w-full  ">
            <div className="flex max-md:flex-col max-md:gap-[16px] md:items-center justify-between max-w-[1400px] w-full mx-auto">
                <div className="flex-auto flex lg:text-3xl leading-[22px] text-lg font-bold lg:leading-10 text-[#37B34A]  flex-wrap">
                    {renderCrumbs()}
                </div>
                {statusSelect &&
                    <div className="flex flex-wrap flex-auto justify-end items-end whitespace-nowrap gap-[16px]">
                        {interviewUser && <button onClick={onInterviewUserClick} className="flex justify-center items-center py-[13px] px-[20px] bg-green-500 text-white rounded-md max-md:flex-1 hover:opacity-70">
                            Add Interview User
                        </button>}
                        {teamUser && <button onClick={onTeamUserClick} className="flex justify-center items-center py-[13px] px-[20px] bg-green-500 text-white rounded-md max-md:flex-1 hover:opacity-70">
                            Add Team User
                        </button>}
                    </div>
                }
            </div>
        </div>


    );
}
