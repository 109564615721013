import logo from "../assets/img/logo.png"
import { Link, Outlet } from 'react-router-dom';

export const JobLayout = () => {
    return (
        <div className="min-h-[100dvh] flex flex-col job-page-layout w-full">
            <header className="fixed top-0 left-0 z-50 w-full h-[90px] lg:h-[160px] flex items-center border-b border-[#CFCFCF] bg-white">
                <div className="w-full max-w-[1210px] lg:px-[20px] m-[auto] flex justify-center lg:justify-start">
                    <Link to={"/"}>
                        <img src={logo} alt='logo' className="m-[auto] lg:m-[0] max-h-[26px] lg:max-h-[initial]" />
                    </Link>
                </div>
            </header>

            <div className="max-w-[1210px] mt-[24px] lg:mt-[50px] mx-auto flex-1 pt-[80px] lg:pt-[160px] w-full">
                <div className="mx-[20px]">
                    <Outlet />
                </div>
            </div>

            <footer className="flex justify-center items-center bg-[#083B4C] h-[86px]">
                <span className="font-[16px] text-white">© {new Date().getFullYear()} EmployJoy.ai . All Rights Reserved.</span>
            </footer>
        </div>
    )
}