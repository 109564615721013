const jobApplicationsBase = 'api/job-applications';

export const jobApplications = (instance) => ({
    async createMeetingForJobApplication(jobApplicationId, applicantId, payload) {
        const { data } = await instance.post(`${jobApplicationsBase}/create-meeting/${jobApplicationId}/${applicantId}`, payload);
        return data;
    },
    async getAllJobApplications(payload) {
        const { data } = await instance.get(`${jobApplicationsBase}`, payload);
        return data;
    },
    async createJobApplication(jobId, payload) {
        try {
            const { data } = await instance.post(`${jobApplicationsBase}/${jobId}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async cancelJobApplication(jobApplicationId, payload) {
        const { data } = await instance.delete(`${jobApplicationsBase}/${jobApplicationId}`, payload);
        return data;
    },
    async getJobApplicants(jobId) {
        try {
            const { data } = await instance.get(`${jobApplicationsBase}/applicants/${jobId}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getJobCandidates(jobId) {
        try {
            const { data } = await instance.get(`${jobApplicationsBase}/candidates/${jobId}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async setApplicationStatus(jobId, applicationId, payload) {
        try {
            const { data } = await instance.post(`${jobApplicationsBase}/setup-status/${jobId}/${applicationId}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getJobApplicationById(jobId, applicationId) {
        try {
            const { data } = await instance.get(`${jobApplicationsBase}/applicants/${jobId}/${applicationId}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async updateClarifications(jobId, applicationId, payload) {
        try {
            const { data } = await instance.post(`${jobApplicationsBase}/clarifications/${jobId}/${applicationId}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async updateApplication(jobId, applicationId, payload) {
        try {
            const { data } = await instance.put(`${jobApplicationsBase}/${jobId}/${applicationId}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },

    async createOfferMeetings(applicationId, payload) {
        try {
            const { data } = await instance.post(`api/offer-meetings/${applicationId}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getOfferMeetings(applicationId) {
        try {
            const { data } = await instance.get(`api/offer-meetings/${applicationId}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async sendOfferEmail(applicationId) {
        try {
            const { data } = await instance.get(`api/offer-meetings/send-email/${applicationId}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getOfferInfo(token) {
        try {
            const { data } = await instance.get(`api/offer-meetings/offer-by-sign/${token}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async signSignatureApplicant(token, payload) {
        try {
            const { data } = await instance.post(`api/offer-meetings/sign-offer/${token}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async signSignatureCompany(applicationId, payload) {
        try {
            const { data } = await instance.post(`api/offer-meetings/sign-offer-by-company/${applicationId}`, payload);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async sendToAIRecomendationRequest({ applicationId, body }) {
        try {
            const { data } = await instance.post(`${jobApplicationsBase}/ai-integration/${applicationId}`, body);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async sendPostInterviewToApplicant({ applicationId }) {
        try {
            const { data } = await instance.get(`${jobApplicationsBase}/send-post-interview-follow-up/${applicationId}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getQuestionsForJobApplications({ applicationId, token }) {
        try {
            const { data } = await instance.get(`${jobApplicationsBase}/questions-by-post-interview/${applicationId}/${token}`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async sendAnswersToPostInterviewQuestions({ applicationId, token, body }) {
        try {
            const { data } = await instance.post(`${jobApplicationsBase}/questions-by-post-interview/${applicationId}/${token}`, body);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getHiringAssessments() {
        try {
            const { data } = await instance.get(`api/hiring-assessments`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getRejectionReasons() {
        try {
            const { data } = await instance.get(`api/custom-items?type=rejection_reason&no_limit=1`);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
});

