function findValue(blocks, key) {
    if(!blocks) return null
    return blocks.reduce((result, block) => {
        const preset = (block.blockPresets || []).find(blockPreset => {
            return blockPreset.key === key;
        });

        if(preset) return preset.value;
        return result;
    }, false);
}

export default findValue