import React, { useState } from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer'

const SBM04_1_1 = () => {
    const [formData, setFormData] = useState({
        customPeriod: '',
        fromDate: '',
        toDate: '',
    });

    const customPeriods = [
        { value: 'customPeriods_1', label: 'Custom Periods 1' },
        { value: 'customPeriods_2', label: 'Custom Periods 2' },
        { value: 'customPeriods_3', label: 'Custom Periods 3' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };


    return (
        <>
            <BreadCrumbWhite crumbs={['Insights', 'Pipeline Summary', 'Export CSV']} />
            <section className="flex flex-auto justify-center lg:py-12 w-full text-zinc-800">
                <div className="flex flex-col items-center lg:my-10 mt-9 mb-20 max-w-[1432px] px-4 w-full">
                    <form onSubmit={handleSubmit} className="flex flex-col w-full py-9 px-8 lg:p-16 bg-white rounded-md shadow-def">
                        <div className="lg:text-3xl text-[28px] leading-[36px] font-bold lg:leading-10 text-zinc-800 w-full pb-[18px] border-b-2 border-solid border-green-500 montserrat">
                            Choose your Customers
                        </div>
                        {/* <div className="shrink-0 mt-5 h-0.5 bg-green-500 max-md:max-w-full" /> */}
                        <div className="w-full flex flex-wrap lg:gap-5 justify-between mt-9 lg:mt-11 text-lg max-md:flex-wrap lg:max-w-[66.66%] max-lg:flex-col">
                            <div className="flex flex-col flex-1 min-w-48">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="Custom Period"
                                    labelClassName="max-md:text-[16px]"
                                    parentClass="mb-[18px] flex-col max-md:gap-[12px]"
                                    name="customPeriod"
                                    id="customPeriod"
                                    value={formData.customPeriod}
                                    onChange={handleChange}
                                    options={customPeriods}
                                />
                            </div>
                            <div className="flex flex-col flex-1 min-w-48 whitespace-nowrap">
                                <InputField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="From" labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px] max-md:text-[16px]"
                                    type="date" name="fromDate" id="fromDate"
                                    value={formData.fromDate} onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col flex-1 min-w-48 whitespace-nowrap">
                                <InputField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    label="To" labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px] max-md:text-[16px]"
                                    type="date" name="toDate" id="toDate"
                                    value={formData.toDate} onChange={handleChange}
                                />
                            </div>
                        </div>
                        <button
                            className="justify-center lg:max-w-[236px] px-4 lg:h-16 h-[60px] mt-5 lg:mt-[24px] lg:text-[22px] text-[19px] font-bold text-center text-white uppercase whitespace-nowrap bg-green-500 rounded-md hover:opacity-70"
                            type='submit'
                        >
                            Download
                        </button>
                    </form>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM04_1_1