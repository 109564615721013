const usersBase = 'api/users';

export const users = (instance) => ({
    async getUser(payload) {
        const { data } = await instance.get(`${usersBase}/me`);
        return data;
    },

    async updateUser(payload) {
        const { data } = await instance.put(`${usersBase}/me`, payload);
        return data;
    },

    async contactUsSend(payload) {
        const { data } = await instance.post(`api/contact-us/send`, payload);
        return data;
    },
    async publicContactUs(payload) {
        const { data } = await instance.post(`api/contact-us`, payload);
        return data;
    },
})