import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import PageLoader from '../components/PageLoader';
import { Footer } from '../components/parts/Footer';
import { getSubscriberRole } from '../redux/slices/applicationSlice';
import { getApplicationByIdFetch, getJobByIdFetch } from '../redux/thunks/jobsThunks';
import { TabsSBM01 } from './SBM01_Form/base/TabsSBM01';
import { crumbsConfig } from './SBM01_Form/config';


const SBM01_10_14_2 = () => {
	const dispatch = useDispatch();
	const { jobId, applicationId } = useParams();
	const { job, isLoading, application } = useSelector(state => state.jobs)

	const subscriberRole = useSelector(getSubscriberRole);

	useEffect(() => {
		if (!jobId || !applicationId) return;
		dispatch(getJobByIdFetch(jobId))
		dispatch(getApplicationByIdFetch({
			applicationId,
			jobId
		}))
	}, [jobId, applicationId, dispatch])

	const crumbs = [crumbsConfig.sub, crumbsConfig.jobs, ['Applicants', `/SBM01_2/${jobId}?tab=applicants`], ["Candidates", `/SBM01_2/${jobId}?tab=candidates`], [application?.fullName, `/SBM01_2/${jobId}?tab=candidates`], "Report Card"]

	const calcTotalPercent = (scores) => {
		const statuses = [
			"Completed",
			"A Score",
			"On-going",
			"Not Started",
		]
		const data = {
			percentage: 0,
			status: "Not Started",
			completed: false
		}
		if (scores?.length) {
			const answered = scores?.filter(item => !isNaN(parseInt(item))) || [];
			data.percentage = Math.floor(answered?.length / (scores?.length || 0) * 100);
		}
		data.completed = data.percentage === 100
		data.status = data.completed ? "Completed" : "Not Started"

		return data
	}

	if (isLoading) {
		return <PageLoader />
	}

	const reportCardData = [
		{
			title: "Clarification",
			// percentage: 100,
			// status: "Completed",
			// completed: true,
			...calcTotalPercent(application?.assessments?.["clarifications"]?.scores),
			borderColor: "border-sky-900",
			linkTo: `/SBM01_10_18/${jobId}/${applicationId}`
		},
		{
			title: "1st Interview",
			// status: "A Score",
			// progress: '56%',
			...calcTotalPercent(application?.assessments?.["1st-interview"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_1/${jobId}/${applicationId}`
		},
		{
			title: "Post Interview Survey",
			// status: "A Score",
			// progress: '20',
			...calcTotalPercent(application?.assessments?.["post_interview_survey"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_2/${jobId}/${applicationId}`
		},
		{
			title: "2nd Interview",
			// status: "On-going",
			...calcTotalPercent(application?.assessments?.["2nd-interview"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_3/${jobId}/${applicationId}`
		},
		{
			title: "Document Review",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["document-review"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_4/${jobId}/${applicationId}`
		},
		{
			title: "Rating of the Job",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["rating-job"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_16/${jobId}/${applicationId}`
		},
		{
			title: "Scores",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["scores"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/scores/${jobId}/${applicationId}`
		},
		{
			title: "AI Evaluation",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["ai-recomendations"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_5/${jobId}/${applicationId}`
		},
		{
			title: "Final Hiring Decisions",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["final-decisions"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_5_5/${jobId}/${applicationId}`
		},
		{
			title: "Training Insights",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["insights"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_6/${jobId}/${applicationId}`
		},
		{
			title: "Offer Meeting",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["offer"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_7/${jobId}/${applicationId}`
		},
		{
			title: "Training Milestones",
			// status: "Not Started",
			...calcTotalPercent(application?.assessments?.["milestones"]?.scores),
			borderColor: "border-gray-200",
			linkTo: `/SBM01_10_8/${jobId}/${applicationId}`
		},
	];


	return (
		<>

			{job && application ?
				<>
					<BreadCrumbWhite crumbs={crumbs} />
					<div className='max-w-[1432px] flex-auto px-4 w-full pt-10 pb-20 lg:pt-[60px] lg:pb-[90px] mx-auto'>
						<p className="text-lg font-semibold text-sky-900">{job.jobTitle} / Candidates</p>
						<h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">
							{application.fullName}
						</h2>
						{
							subscriberRole !== "interviewer" ?
								<TabsSBM01 activeLink={'report-card'} />
								:
								null
						}
						<h2 className='my-9 lg:my-[60px] text-[28px] lg:text-[30px] font-bold text-[#333333]'>Report Card</h2>
						<div className='flex items-center gap-3 lg:gap-[18px] max-lg:mb-4'>
							{/* <div className='aspect-square w-6 lg:w-[50px] flex items-center justify-center'>
								<Checkbox />
							</div> */}
							<p className='lg:text-2xl text-lg font-bold text-[#37B34A]'>{application.fullName}</p>
							<p className='text-base lg:text-lg font-normal text-[#666666]'>Applied Date {format(application.createdAt, 'MM/dd/yyyy')}</p>
						</div>
						<div className='flex items-center overflow-auto gap-[2px] border-b border-solid border-[#DEDED9]'>
							{(subscriberRole !== "interviewer" ? reportCardData : reportCardData.slice(0, 4)).map((item, index) => <div
								className='flex-1 flex flex-col items-center min-w-[150px]'
								key={index}
							>
								<div className={classNames('w-full h-[90px] flex items-center flex-col gap-[6px] justify-center border-b-[5px] border-solid', item.completed ? 'border-[#003578]' : 'border-[#EBE9EA]')}>
									<Link to={item.linkTo} className='text-base font-bold text-[#37B34A] max-w-[150px] line-clamp-2 text-center'>{item.title}</Link>
									<p className='text-base font-normal text-[#777777]'>{item.percentage ? item.percentage + " %" : 'Send'}</p>
								</div>
								<div className='h-[90px] flex items-center justify-center'>
									<p className='text-base font-normal text-[#777777]'>{item.status} {item.progress && <span className='text-[#37B34A]'>({item.progress})</span>}</p>
								</div>
							</div>)}
						</div>

					</div>
				</>
				: <h2 className="text-4xl font-bold text-center my-12">Not found</h2>}
			<Footer hideCopyright={true} />
		</>
	)
}

export default SBM01_10_14_2
