import classNames from "classnames";
import { forwardRef } from "react";

const TextareaField = forwardRef(({ label, labelClassName, type = "text", name, id, value, onInput = ()=>{}, onChange, error, disabled, inputBg = "bg-[#F0F3F8]", placeholder = "", afterLabel = null, height = 256, className, wrapperClassName, ...props }, ref) => (
    <div className={`flex flex-col gap-[16px] flex-1 mb-[18px] ${wrapperClassName}`}>
        { label ? <label htmlFor={id} className={labelClassName ? labelClassName : "text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]"}>{label}</label> : null }
        {afterLabel}
        <textarea
            id={id}
            name={name}
            className={classNames(`rounded-md py-[18px] px-[18px] text-[18px] focus:outline-none focus:ring-2 focus:ring-green-500 ${error ? 'border-red-500 bg-red-100' : inputBg} h-[54px] w-full`, className)}
            value={value}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            style={{
                resize: "none",
                height: height 
            }}
            onInput={onInput}
            ref={ref}
            {...props}
        />
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
));

export default TextareaField;