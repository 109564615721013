import { NumberQuestion } from "./NumberQuestion";
import { RangeQuestion } from "./RangeQuestion";
import { TextQuestion } from "./TextQuestion";
import { VariantsQuestion } from "./VariantsQuestion";
import { YesNoQuestion } from "./YesNoQuestion";
import { YesNoTextQuestion } from "./YesNoTextQuestion";

export const QuestionTypes = {
    'yes-no': YesNoQuestion,
    'text':  TextQuestion,
    'range': RangeQuestion,
    'variants': VariantsQuestion,
    'number': NumberQuestion,
    'yes-no-text': YesNoTextQuestion,
}