import React, { useState, useEffect, Fragment } from "react";
import Checkbox from "../../components/base/Checkbox";
import ContactUsLinkBlock from "../../components/parts/ContactUsLinkBlock";
// import { useDispatch, useSelector } from "react-redux";
// import { getJobPipelinesListFetch } from "../../redux/thunks/jobsThunks";
import api from "../../api";
import PageLoader from "../../components/PageLoader";

const Step4 = ({ setContactFormOpen, payload, setPayload, bottomPaste = null }) => {
    console.log('Step4 payload:', payload);

    // const dispatch = useDispatch();

    // const data = [
    //     'Clarification',
    //     '1st Interview',
    //     `Post Interview <br/> Survey`,
    //     '2nd Interview',
    //     'Document<br/>Review',
    //     'Rating of the<br/> Job',
    //     'AI Evaluation',
    //     `Final Hiring<br/>Decisions`,
    //     `Training<br/>Insights`,
    //     `Offer<br/>Meeting`,
    //     `Training<br/>Milestones`
    // ];

    // const data2 = [
    //     '1st Interview',
    //     `Post Interview <br/> Survey`,
    //     '2nd Interview',
    //     'Rating of the<br/> Job',
    //     'AI Evaluation',
    //     `Final Hiring<br/>Decisions`,
    //     `Training<br/>Insights`,
    //     `Offer<br/>Meeting`,
    //     `Training<br/>Milestones`
    // ];

    // const [selected, setSelected] = useState(1);

    const [data, setData] = useState(null);

    const getJobPipelinesListByIndustryId = async(id) => {
        let res = await api.jobs.getJobPipelinesListByIndustryId(payload.industrieId);

        setData(res.data)
    }

	useEffect(() => {
        if(payload.industrieId){
            getJobPipelinesListByIndustryId();
        }

		// dispatch(getJobPipelinesListFetch());
	}, [payload.industrieId]);

    return (
        <>
            <div className='text-center flex gap-3 items-center justify-center flex-col'>
                <span className='lg:text-[40px] text-4xl font-bold'>Let’s Get Started</span>
                {/*<span className='lg:text-[18px] text-base leading-[22px] lg:leading-[30px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,<br />*/}
                {/*    bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut<br />*/}
                {/*    consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.*/}
                {/*</span>*/}
            </div>

            <div className="border border-neutral-200 py-9 px-8 lg:px-[60px] lg:py-[50px]">
                {
                    data?.map((item, index)=>{
                        return <Fragment key={index}>
                            <div>
                                <Checkbox
                                    className="gap-4"
                                    text={<span className={`checkmark-label-optional font-semibold text-lg lg:text-[26px] ${payload.pipelineId === item.id ? 'text-[#37B34A]' : ''}`}>
                                        { item.title }
                                    </span>}
                                    checked={payload.pipelineId === item.id}
                                    onChange={(val) => {
                                        if(val){
                                            setPayload({ ...payload, pipelineId: item.id });
                                        } else {
                                            setPayload({ ...payload, pipelineId: null });
                                        }
                                    }}
                                />
                                <div className="lg:pl-12 mt-5 lg:mt-6 flex w-full overflow-auto">
                                    { item.pipelines.map((el, idx) =>
                                        <div
                                            key={idx}
                                            className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] px-2 max-lg:min-w-32 flex flex-auto items-center justify-center text-center">
                                            <span className='text-[#003578] text-base leading-5 font-semibold' dangerouslySetInnerHTML={{ __html: el.title }}></span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            { index !== data?.length-1 ? <hr className="my-[50px] border-b border-neutral-200" /> : null }
                        </Fragment>
                    }) || <PageLoader />
                }
                <ContactUsLinkBlock
                    topPaste={bottomPaste}
                    setContactFormOpen={setContactFormOpen}
                />
            </div>
        </>
    )
}

export default Step4
