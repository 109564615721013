import React, { useEffect, useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer';

import { ReactComponent as SearchIcon } from "../assets/img/search-icon-grey.svg";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { crumbsConfig } from "./SBM01_Form/config";
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobsFetch, getJobsByCompanyFetch } from '../redux/thunks/jobsThunks';
import { format } from 'date-fns';
import PageLoader from '../components/PageLoader';
import { Country, State, City } from 'country-state-city';
import { getUser } from '../redux/slices/applicationSlice';
import { fetchAllIndustries } from '../redux/thunks/companiesThunks';


export const JobDetails = ({ data }) => (
    <Link to={`/SBM01_2/${data.id}`} className='flex items-start gap-1'>
        <div className='flex items-center justify-center shrink-0 w-9 h-9'>
            <div className="w-2 h-2 bg-sky-900" />
        </div>
        <div className="flex flex-col grow shrink-0 items-start basis-0 w-fit font-semibold leading-9 text-stone-500 ">
            <div className="self-stretch text-xl leading-9 font-bold text-green-500 max-md:max-w-full">
                {data.jobTitle}
            </div>
            <div className="self-stretch font-normal">{data.city}</div>
            <div className="flex gap-2 whitespace-nowrap">
                <div className="grow">Date Started:</div>
                <div className="grow">{format(data.createdAt, "dd/MM/yyyy")}</div>
            </div>
            <div className="flex gap-2 whitespace-nowrap">
                <div className="grow">Volume of Applicants:</div>
                <div>{data.candidatesCount}</div>
            </div>
            <div className="flex gap-2 whitespace-nowrap">
                <div className="grow"># of Offers:</div>
                <div className="flex-auto">{"5"}</div>
                {/* TODO */}
            </div>
        </div>
    </Link>
);

const SBM01 = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { companyJobs, isLoading } = useSelector(state => state.jobs)
    const { industries, isLoading: isIndustriesLoading } = useSelector(state => state.companies)

    const [shownCompanyJobs, setShownCompanyJobs] = useState([])

    const user = useSelector(getUser)

    const [formData, setFormData] = useState({
        // industrieId: '',
        // location: '',
        // status: '',
        limit: 1000
    });
    const [searchParams, setSearchParams] = useSearchParams(formData)

    const [search, setSearch] = useState("")

    const industryOptions = !isIndustriesLoading ? [
        { value: '', label: 'All Departments', },
        ...industries.map(item => ({ label: item?.name, value: item?.id }))
    ] : [];

    const locations = Country.getAllCountries().map(item => ({ value: item.name, label: item.name }));


    const status = [
        { value: 'active', label: 'Active' },
        { value: 'disabled', label: 'Disabled' },
        { value: 'pending', label: 'Pending' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        setSearchParams(prev => {
            prev.set(name, value)
            return prev
        })
    };

    useEffect(() => {
        dispatch(fetchAllIndustries())
    }, [])

    useEffect(() => {
        if (!companyJobs?.length) return;
        setShownCompanyJobs(companyJobs.filter(({ jobTitle }) => jobTitle.toLowerCase().includes(search)))
    }, [search, companyJobs])

    useEffect(() => {
        if (!user?.companies?.length || !user?.companies[0]?.company?.id) return
        dispatch(getJobsByCompanyFetch({ companyId: user.companies[0].company.id, filters: searchParams.toString() }))

    }, [user, searchParams])

    return (
        <>
            <BreadCrumbWhite crumbs={[crumbsConfig.sub, crumbsConfig.jobs]} />
            <section className="flex justify-center flex-auto w-full text-zinc-800">
                <div className="flex flex-col items-center pt-9 pb-20 lg:pt-16 lg:pb-24 w-full max-w-[1432px] px-4">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-10 lg:mb-11'>
                        <div className="lg:text-[40px] text-4xl font-bold lg:leading-[48px] text-zinc-800">
                            Current Job Openings
                        </div>
                        {/*<p className='text-pretty'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                    </div>
                    <div className="flex flex-col px-8 py-9 lg:px-[60px] lg:py-[50px] text-lg w-full bg-white rounded-md border border-solid border-zinc-300">
                        <div className="flex max-lg:flex-col gap-[14px] lg:gap-8 w-full text-neutral-400">
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="industrieId"
                                    id="industrieId"
                                    parentClass=''
                                    value={formData.industrieId}
                                    onChange={handleChange}
                                    options={industryOptions}
                                />
                            </div>
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="location"
                                    id="location"
                                    parentClass=''
                                    value={formData.location}
                                    onChange={handleChange}
                                    options={locations}
                                />
                            </div>
                            <div className="flex items-center w-full lg:w-64">
                                <SelectField
                                    inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                    name="status"
                                    id="status"
                                    parentClass=''
                                    value={formData.status}
                                    onChange={handleChange}
                                    options={status}
                                />
                            </div>
                            <div className="flex items-center max-lg:w-full lg:ml-auto">
                                <div
                                    className="flex items-center flex-auto bg-white rounded-md border border-solid border-zinc-300 outline-none pr-4"
                                >
                                    <input
                                        type="search"
                                        className="flex-1 px-4 py-2 rounded-md outline-none h-[52px] lg:h-[54px]"
                                        placeholder="Cleaning Technician"
                                        onChange={(e) => setSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleChange({ target: { name: "search", value: search } })
                                            }
                                        }}
                                        id="search"
                                        name='search'
                                        value={search}
                                    />
                                    <span className="flex">
                                        <SearchIcon />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="shrink-0  my-9 lg:my-[50px] h-px border border-solid bg-neutral-200 border-neutral-200" />
                        {
                            !isLoading && shownCompanyJobs.length ?
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-9">
                                    {
                                        shownCompanyJobs.map(job => (
                                            <JobDetails key={job.id} data={job} />
                                        ))
                                    }
                                </div>
                                : isLoading
                                    ?
                                    <PageLoader />
                                    :
                                    <h2 className='text-center text-4xl font-bold'>There is no jobs yet...</h2>
                        }
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01
