import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const getAllJobsFetch = createAsyncThunk(
    '/get-jobs/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobs.getAllJobs();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getJobByIdFetch = createAsyncThunk(
    '/get-job-by-id/',
    async (jobId, { rejectWithValue }) => {
        try {
            const res = await api.jobs.getJobsById(jobId);
            if (res.success) {
                return res.data;
            }
            return rejectWithValue(res.data);
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getApplicationByIdFetch = createAsyncThunk(
    '/get-application-by-id/',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await api.jobApplications.getJobApplicationById(payload.jobId, payload.applicationId);
            if (res.success) {
                // dispatch(getCallTokenFetch(res.data.id))
                return res.data;
            }
            return rejectWithValue(res.data);
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getCallTokenFetch = createAsyncThunk(
    '/get-call-token/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.communications.getCallToken(payload);
            console.log(res)
            if (res.success) {

                return res.data;
            }
            return rejectWithValue(res.data);
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const cancelJobApplicationFetch = createAsyncThunk(
    '/cancel-jobs/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobs.cancelJobApplication(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getCandidatesFetch = createAsyncThunk(
    '/jobs-candidates/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplications.getJobCandidates(payload);
            if (res.success) {
                return {
                    data: res.data,
                    pagination: res.pagination
                };
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getApplicantsFetch = createAsyncThunk(
    '/jobs-applicants/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplications.getJobApplicants(payload);
            if (res.success) {
                return {
                    data: res.data,
                    pagination: res.pagination
                };
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getJobsCompaniesFetch = createAsyncThunk(
    '/jobs-candidates/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobs.getJobsCompanies(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getJobsByCompanyFetch = createAsyncThunk(
    '/jobs-by-company/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobs.getJobsByCompany(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const changeJobFetch = createAsyncThunk(
    '/jobs-change/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobs.changeJob(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const deleteJobFetch = createAsyncThunk(
    '/job-delete/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobs.deleteJob(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getJobPipelinesListFetch = createAsyncThunk(
    '/job-pipelines/list',
    async (_, { rejectWithValue }) => {
        try {
            const res = await api.jobs.getJobPipelinesList();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getPublicJobFetch = createAsyncThunk(
    '/jobs/public',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.jobs.getPublicJob(id);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
